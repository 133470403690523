import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Trans, useTranslation } from 'react-i18next';
import WhatsApp from "./Whatsapp.js";
import Language  from './Language.js';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PlaceIcon from '@mui/icons-material/Place';
import NumbersIcon from '@mui/icons-material/Numbers';
import OpeningTime from './OpeningTime'; 
import CircleIcon from '@mui/icons-material/Circle';
import LanguageIcon from '@mui/icons-material/Language';
import ShareIcon from '@mui/icons-material/Share';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function sendEmail(email) {
  var subject = 'From mirainails.nl: ';
  document.location = "mailto:"+email+"?subject="+subject;
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" mt={2}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Mirai Nails
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function Footer() {
  const { t } = useTranslation();
  return (     
        <Box sx={{ bgcolor: '#c2a01e', p: 6, flexGrow: 1 }} component="footer">
          <Grid container spacing={{ xs: 2, md: 8}} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item  md={6} >
              <Item>
                <Typography
                  variant="h5"
                  align="center"
                  color="text.secondary"
                  component="div"
                  mt={2}
                  >
                     
                    {t('opening_hours')}
                  </Typography>
                  <List>
                  {OpeningTime.map((item) => (
                    <ListItem disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        <CircleIcon  sx={{fontSize:'small', color:'error'}}/>
                      </ListItemIcon>
                      <ListItemText primary={t(item.title)}  />
                      <ListItemIcon><ListItemText primary={t(item.from+ ' - '+item.to)}  /></ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  
                  
                ))}
                  </List> 
               
              
            
              </Item>
            </Grid>

            <Grid item  md={6} >
                <Typography
                  variant="h5"
                  align="center"
                  color="text.secondary"
                  component="div"
                  mt={2}
                  >
                    Contact
               
                <List>
                
                <ListItem disablePadding>
                    <ListItemButton >
                      <ListItemIcon>
                      
                      </ListItemIcon>
                      <ListItemText primary="" />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton href='tel:+31643256620'>
                      <ListItemIcon>
                        <LocalPhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary=":  06-43256620" />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton onClick={() => sendEmail('mirai.nails21@gmail.com')}>
                      <ListItemIcon>
                        <MailOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary=": mirai.nails21@gmail.com" />
                    </ListItemButton>
                  </ListItem>

                </List>

                <Divider />

                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        <PlaceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Spuistraat 56, 1012 TV , Amsterdam " />
                    </ListItemButton>
                  </ListItem>
                
                  <ListItem disablePadding>
                    <ListItemButton >
                    <ListItemIcon>
                        < NumbersIcon/>
                      </ListItemIcon>
                      <ListItemText primary=" KVK: 89434994 " />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton >
                    <ListItemIcon>
                        < LanguageIcon/>
                      </ListItemIcon>
                     <Language/>
                    </ListItemButton>
                  </ListItem>
                
                  <ListItem disablePadding>
                    <ListItemButton >
                      <ListItemIcon>
                      
                      </ListItemIcon>
                      <ListItemText primary="" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton >
                    <ListItemIcon>
                        <ShareIcon />
                      </ListItemIcon>
                      <Link color="inherit" href="https://www.instagram.com/mirai.nails/"><InstagramIcon /> </Link>
                     
                      <Link color="inherit" href="https://www.facebook.com/profile.php?id=100085215604912"><FacebookIcon/> </Link>
                    </ListItemButton>
                  </ListItem>

                 

                </List>
                </Typography>
            
            </Grid>
            
          </Grid>
           <WhatsApp/>
            
            <Copyright/>
            
        </Box>
          
  );
}